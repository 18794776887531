.girlPage {
  display:              flex;
  justify-content:      center;
  align-items:          center;
  flex-direction:       column;
  width:                100%;
  min-height:           100vh;
  position:             relative;
  overflow:             hidden;
  background:           url('/public/img/street-bkgnd-2.jpg') no-repeat center center;
  background-size:      cover;
  padding:              20px;
}

.girlPage::before {
  content:              '';
  position:             absolute;
  top:                  0;
  left:                 0;
  width:                100%;
  height:               100%;
  background:           rgba(0, 0, 0, 0.5); /* Black translucent tint */
  z-index:              1;
}

.girlPageContainer {
  display:              flex;
  flex-direction:       row;
  justify-content:      center;
  align-items:          center;
  max-width:            1024px;
  width:                100%;
  z-index:              2;
}

.leftColumn,
.rightColumn {
  flex:                 1;
  display:              flex;
  flex-direction:       column;
  align-items:          center;
  justify-content:      center;
  padding:              20px;
}

.nftImageContainer {
  width:                100%;
  min-width:            30em;
  aspect-ratio:         9 / 16;
  display:              flex;
  align-items:          center;
  justify-content:      center;
  overflow:             hidden;
}

.nftImage {
  width:                100%;
  height:               75vh;
  object-fit:           cover; /* Ensure the image covers the container */
  object-position:      center; /* Centering the image */
  border:               2px solid white;
  border-radius:        10px;
}

.nftTitle {
  font-size:            3.8em;
  font-family:          var(--primary-font);
  color:                white;
  margin-bottom:        -0.2em;
  text-shadow:          0 0 10px rgba(255, 0, 0, 0.7),
                        0 0 20px rgba(255, 0, 0, 0.5),
                        0 0 30px rgba(255, 0, 0, 0.3);
}

.nftDescription {
  color:                var(--white-color);
  text-align:           justify;
  margin-bottom:        1em;
  max-width:            25em;
}

.openseaLink {
  font-size:            1.2em;
  color:                #1e90ff;
  text-decoration:      none;
}

@media (max-width: 768px) {
  .girlPageContainer {
    flex-direction: column;
    padding: 10px;
  }

  .nftImageContainer {
    min-width:  0;
    width:      22em;
    margin:     8em 0 2em 0;
  }

  .nftImage {
    height: 60vh;
  }

  .leftColumn, .rightColumn {
    width:    100%;
    padding:  10px 20px;
  }

  .nftTitle {
    font-size: 2.5em;
    text-align: center;
  }

  .nftDescription, .openseaLink {
    text-align: center;
  }
}

@media (max-width: 480px) {

  .girlPageContainer {
    padding: 5px;
  }

  .nftImageContainer {
    min-width:  0;
    width:      22em;
    margin:     2em 0 2em 0;
  }

  .nftImage {
    height:     60vh;
  }

  .leftColumn, .rightColumn {
    padding: 10px 15px;
  }

  .nftTitle {
    font-size: 1.8em;
  }

  .nftDescription {
    font-size: 1em;
  }

  .openseaLink {
    font-size: 1em;
  }
}
