
:root {
  --primary-color:      #111;
  --secondary-color:    #2ecc71;
  --white-color:        #fff;

  --background-color:   #333;
  --container-color:    #889;

  --light-sand-color:   #e4d6c2;


  --hero-font:          'Great Vibes', serif;
  --alt-hero-font:      'Von Muellerhoff', serif;
  --title-font:         'Play', serif;
  --subtitle-font:      'Permanent Marker', serif;
  --primary-font:       'Audiowide', serif;
  --link-font:          'Audiowide', serif;
  --tiny-font:          'Audiowide', sans-serif;
}


html, body {
    font-family:      var(--primary-font);
    color:            var(--primary-color);
    background-color: var(--background-color);

    margin:             0;
    padding:            0;

    min-height:         100vh;
    box-sizing:         border-box;
    overflow-x:         hidden;
}


h1 {
  font-size: 3.8em;
  font-family: var(--primary-font);
  color: white;
  text-shadow:
    0 0 10px rgba(255, 0, 0, 0.7),
    0 0 20px rgba(255, 0, 0, 0.5),
    0 0 30px rgba(255, 0, 0, 0.3);
}


h2 {
    font-size:      3em;
    font-family:    var(--subtitle-font);
    color:          var(--white-color);
}

h3   {
    font-size:      2.4em;
    font-family:    var(--title-font);
    color:          var(--white-color);
}


.page {
    width:            100%;
    min-height:       100vh;
    overflow:         hidden;

    background-color: var(--background-color);

    display:          flex;
    flex-direction:   column;

    justify-content:  center;
    align-items:      center;
}
