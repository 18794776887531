
.heroSection {
    background-image:       url('/public/img/squeezed-sandrine.jpg');
    background-size:        cover;
    background-position:    center;

    width:                  100%;
    height:                 100vh;

    display:                flex;
    justify-content:        center;
    align-items:            center;
    text-align:             center;

    padding:                0 20px; /* Add padding to handle content on smaller screens */
    margin:                 0;
    overflow:               hidden;
}

.heroSection h1 {
    margin-top: 2.6em;
}

.heroSection h3 {
    margin-top: -1.6em;
}

.content {
    max-width:      800px;
}
