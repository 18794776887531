.aboutSection {
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: black;
  color: white;
  padding: 20px;
  box-sizing: border-box;
}

.leftColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 40px 20px 60px; /* Increased left padding */
  box-sizing: border-box;
}

.rightColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 60px 20px 40px; /* Increased right padding */
  box-sizing: border-box;
}

.title {
  font-size: 3em;
  margin: 0;
  padding: 0;
}

.subtitle {
  font-size: 2em;
  margin: 0;
  padding: 0;
}

.description {
  text-align: justify;
  font-size: 1.2em;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .aboutSection {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .leftColumn, .rightColumn {
    padding: 10px 20px;
    align-items: center;
    text-align: center;
  }

  .title {
    font-size: 2.5em;
  }

  .subtitle {
    font-size: 1.8em;
  }

  .description {
    font-size: 1em;
    margin-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .aboutSection {
    padding: 5px;
  }

  .leftColumn, .rightColumn {
    padding: 10px 15px;
  }

  .title {
    font-size: 2em;
  }

  .subtitle {
    font-size: 1.5em;
  }

  .description {
    font-size: 0.9em;
    margin-bottom: 10px;
  }
}
