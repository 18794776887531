.catalogSection {
  position:             relative;
  display:              flex;
  flex-direction:       column;
  align-items:          center;

  width:                100%;
  min-height:           100vh;
  background:           url('/public/img/catalog-bkgnd.jpg') no-repeat center center;
  background-size:      cover;
  padding:              20px;

}

.catalogSection::before {
  content:              '';
  position:             absolute;
  top:                  0;
  left:                 0;
  width:                100%;
  height:               100%;
  background:           rgba(0, 0, 0, 0.5); /* Black translucent tint */
  z-index:              1;
}

.catlogTitle, .catalogGrid, .paginationControls {
  position:             relative;
  z-index:              2; /* Ensure content is above the tint */

}

.catlogTitle {
    justify-content:    center;
    align-items:        center;
    text-align:         center;
    color:              var(--white-color);

    padding:            10px;
    border-radius:      10px;
    min-height:         4em;
}

.catlogTitle h1 {
    margin:             1em 0 0.75em 0;
    padding:            0.2em;
}

.paginationControls {
  display:              flex;
  justify-content:      space-between;
  width:                100%;
  max-width:            800px;
  margin-top:           20px;
  z-index:              3;
}

.button {
  background:           rgba(0, 0, 0, 0.7);
  border:               1px solid #fff;
  color:                white;
  padding:              1em 2em;
  cursor:               pointer;
  border-radius:        0.5em;
  font-weight:          bold;
  transition:           transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.button:hover {
  transform:            scale(1.1);
}

.button:active {
  background-color:     #555;
}

.button.disabled {
  cursor:               not-allowed;
  opacity:              0.5;
}

.catalogGrid {
  display:              flex;
  flex-wrap:            wrap;
  justify-content:      center;
  gap:                  2em;
  z-index:              3;
}

.nftThumbnail {
  flex:                 0 0 calc(33.3333% - 10px);
  background:           white;
  border:               4px solid white;
  border-radius:        0.5em;
  transition:           transform 0.3s ease-in-out, filter 0.3s ease-in-out;
  cursor:               pointer;
  width:                300px;
  box-sizing:           border-box;
}

.nftThumbnail img {
  width:                100%;
  height:               auto;
  display:              block;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.nftThumbnail p {
  margin:               0;
  padding:              10px;
  text-align:           center;
}

.nftThumbnail:hover {
  transform:            scale(1.05);
  filter:               brightness(1.2);
}

@media (max-width: 768px) {
  .nftThumbnail {
    width: calc(50% - 20px);
  }

  .paginationControls {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .nftThumbnail {
    width: 100%;
  }

  .paginationControls {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .button {
    width: 50%;
    padding: 1em;
    text-align: center;
  }
}
