.footer {
    background-color:   black;
    color:              white;

    min-height:         20vh;
    width:              100%;
    overflow:           hidden;
    padding:            6em 0 2em 0;
}

.footer a {
    color:              #eef;
    text-decoration:    none;
    transition:         color 0.3s ease-in-out;
}

.footer a:hover {
    color:  #888;
}

.siteBanner h2 {
    text-align:         center;
}

.siteBanner h3 {
    text-align:         center;
    margin-top: -1.2em;

}

.footer .siteBanner a {
    color:              var(--text-color);
    text-decoration:    none;
}

.row {
    display:            flex;
    flex-direction:     row;
    justify-content:    center;  /* Center the content of the row */
}

.column {
    flex:               1;
    padding:            1em 1em 2em 2em;
    display:            flex;
    flex-direction:     column;
    align-items:        center;  /* Center the content of the columns */
    justify-content:    center;
}

.footerLinks {
    list-style-type:    none;
    text-decoration:    none;
    line-height:        3em;
}

.footerLinks ul {
    display:            flex;
    flex-direction:     column;

    padding:            0;
    margin:             0;
}

.footerLinks li {
    text-decoration:    none;
}

.logo img {
    max-width:          150px;
}

.copyrightCol {
    flex:               1;
    padding:            0 0 0.5em 0;
    font-size:          smaller;
}

.copyrightMessage {
    font-family:        var(--tiny-font);
    text-align:         center;
}

.copyrightMessage a {
    color:              #fff;
    text-decoration:    none;
}

