.header {
    width:              100%;
    position:           fixed;
    top:                0;
    left:               0;
    z-index:            1000;
    padding:            1em 2em;
    box-sizing:         border-box;
    background:         linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0));
    color:              #fff;
    transition:         background-color 1.2s, color 0.8s;
    text-shadow:        2px 2px 4px rgba(0, 0, 0, 0.7);
}

.scrolled {
    background:         linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    background-color:   white;
    color:              black;
    text-shadow:        none;
}

.header .menu {
    display:            flex;
    justify-content:    space-between;
    width:              100%;
}

.leftMenu, .rightMenu {
    display:            flex;
    list-style:         none;
    margin:             0;
    padding:            0;
}

.leftMenu li {
    margin-right:       1em;
    padding:            0;
}

.rightMenu li {
    margin-left:        1em;
    padding:            0;
}

.header a {
    font-family:        var(--link-font), sans-serif;
    font-size:          1.2em;
    font-weight:        normal;
    text-decoration:    none;
    color:              var(--white-color);
    transition:         transform 0.3s ease-in-out, color 0.3s ease-in-out;
}

.scrolled a {
    color:              black;
}

.header a:hover {
    color:              var(--secondary-color);
}
